@import "~bootstrap/dist/css/bootstrap.css";
@import "../Admin-template/css/mdb.min.css";
@import "../Admin-template/css/style.min.css";
@import "../Admin-template/css/addons/datatables.css";

.btn-success {
    background: #16478D!important;
}

.sidebar-fixed .logo-wrapper {
    padding: 1.5rem;
}

.list-group-item.active {
    z-index: 0!important;
    color: #fff;
    background-color: #0a3764!important;
    border-color: #0a3764!important;
}
.btn-primary {
    background-color: #0a3764!important;
}
.btn-primary:not([disabled]):not(.disabled).active, .btn-primary:not([disabled]):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: #0a3764!important;
}
.loader {
    background-image: url("../Admin-template/img/ajax-loader.gif");
    height: 15px;
    width: 128px;
    margin-top: 30px;
    text-align: center;
}
.sidebar-fixed {
    padding: 0 .5rem .5rem;
}
